import moduleImage01 from '@/images/module-item-01.png'
import moduleImageWebp01 from '@/images/module-item-01.webp'
import moduleImage02 from '@/images/module-item-02.png'
import moduleImageWebp02 from '@/images/module-item-02.webp'
import moduleImage03 from '@/images/module-item-03.png'
import moduleImageWebp03 from '@/images/module-item-03.webp'
import moduleImage04 from '@/images/module-item-04.webp'
import moduleImageWebp04 from '@/images/module-item-04.webp'
import moduleImage05 from '@/images/module-item-05.webp'
import moduleImageWebp05 from '@/images/module-item-05.webp'
import moduleImage06 from '@/images/module-item-06.webp'
import moduleImageWebp06 from '@/images/module-item-06.webp'
import moduleImage07 from '@/images/module-item-07.png'
import moduleImageWebp07 from '@/images/module-item-07.webp'
import moduleImage08 from '@/images/module-item-08.png'
import moduleImageWebp08 from '@/images/module-item-08.webp'
import moduleImage09 from '@/images/module-item-09.png'
import moduleImageWebp09 from '@/images/module-item-09.webp'
import moduleImage10 from '@/images/module-item-10.png'
import moduleImageWebp10 from '@/images/module-item-10.webp'
import moduleImage11 from '@/images/module-item-11.png'
import moduleImageWebp11 from '@/images/module-item-11.webp'
import moduleImage12 from '@/images/module-item-12.png'
import moduleImageWebp12 from '@/images/module-item-12.webp'
import moduleImage13 from '@/images/module-item-13.png'
import moduleImageWebp13 from '@/images/module-item-13.webp'
import moduleImage14 from '@/images/module-item-14.png'
import moduleImageWebp14 from '@/images/module-item-14.webp'

export const thumbList = [
  {
    moduleId: 1,
    path: moduleImage01,
    webpPath: moduleImageWebp01,
    title: '第1回　臨床医向け認知機能評価（ACUMEN#1）',
    videoTime: '23:40',
    speaker: {
      facilitator: 'Lakshmi N. Yatham 教授',
      expert: 'Kamilla Miskowiak教授(コペンハーゲン大学、デンマーク)',
    },
  },
  {
    moduleId: 2,
    path: moduleImage02,
    webpPath: moduleImageWebp02,
    title: '第2回　精神医学における認知機能の神経生物学（ACUMEN#2）',
    videoTime: '32:53',
    speaker: {
      facilitator: 'Lakshmi N. Yatham 教授',
      expert: 'Gin S. Malhi教授(シドニー大学、オーストラリア)',
    },
    detail: '精神医学における認知機能の神経生物学についての動画です。',
  },
  {
    moduleId: 8,
    path: moduleImage08,
    webpPath: moduleImageWebp08,
    title:
      '第3回　統合失調症における認知機能障害の長期的転帰及び予防（ACUMEN#8）',
    videoTime: '31:48',
    speaker: {
      facilitator: 'Lakshmi N. Yatham 教授',
      expert: 'Richard S.E. Keefe教授(デューク大学、アメリカ)',
    },
    detail: '精神医学における認知機能の神経生物学についての動画です。',
  },
  {
    moduleId: 13,
    path: moduleImage13,
    webpPath: moduleImageWebp13,
    title: '第4回　気分障害における認知機能及び機能的改善療法（ACUMEN#13）',
    videoTime: '27:21',
    speaker: {
      facilitator: 'Lakshmi N. Yatham 教授',
      expert: 'Eduard Vieta教授(バルセロナ大学、スペイン)',
    },
    detail: '精神医学における認知機能の神経生物学についての動画です。',
  },
  {
    moduleId: 5,
    path: moduleImage05,
    webpPath: moduleImageWebp05,
    title: '第5回　統合失調症における認知障害（ACUMEN#5）',
    videoTime: '22:25',
    speaker: {
      facilitator: 'Lakshmi N. Yatham 教授',
      expert: 'Philip D. Harvey教授(マイアミ大学)',
    },
    detail: undefined,
  },
  {
    moduleId: 7,
    path: moduleImage07,
    webpPath: moduleImageWebp07,
    title: '第6回　認知機能に対する向精神薬の作用（ACUMEN#7）',
    videoTime: '39:58',
    speaker: {
      facilitator: 'Lakshmi N. Yatham 教授',
      expert: 'Joseph F. Goldberg博士(マウントサイナイアイカーン医科大学)',
    },
    detail: undefined,
  },
  {
    moduleId: 11,
    path: moduleImage11,
    webpPath: moduleImageWebp11,
    title: '第7回 統合失調症における認知機能障害の薬理学的治療（ACUMEN#11）',
    videoTime: '20:06',
    speaker: {
      facilitator: 'Eduard Vieta教授(バルセロナ大学)',
      expert: '中込和幸先生 (国立精神神経医療研究センター）',
    },
    detail: undefined,
  },
  {
    moduleId: 9,
    path: moduleImage09,
    webpPath: moduleImageWebp09,
    title: '第8回 双極I型障害における長期的転帰及び認知障害の予防（ACUMEN#9）',
    videoTime: '26:14',
    speaker: {
      facilitator: 'Eduard Vieta教授(バルセロナ大学)',
      expert: 'Guy Goodwin名誉教授 (オックスフォード大学)',
    },
    detail: undefined,
  },
  {
    moduleId: 10,
    path: moduleImage10,
    webpPath: moduleImageWebp10,
    title: '第9回 双極I型障害における認知障害の薬理学的治療（ACUMEN#10）',
    videoTime: '19:10',
    speaker: {
      facilitator: 'Eduard Vieta教授(バルセロナ大学)',
      expert: 'Michael Berk教授 (ディーキン大学)',
    },
    detail: undefined,
  },
  {
    moduleId: 12,
    path: moduleImage12,
    webpPath: moduleImageWebp12,
    title: '第10回 双極II型障害における認知機能障害の管理（ACUMEN#12）',
    videoTime: '21:47',
    speaker: {
      facilitator: 'Lakshmi N. Yatham教授',
      expert: 'Glenda MacQueen教授 (カルガリー大学)',
    },
    detail: undefined,
  },
  {
    moduleId: 3,
    path: moduleImage03,
    webpPath: moduleImageWebp03,
    title: '第11回 高リスク双極性障害における認知機能（ACUMEN#3）',
    videoTime: '29:01',
    speaker: {
      facilitator: 'Lakshmi N. Yatham教授',
      expert: 'Allan Young教授 (キングスカレッジロンドン)',
    },
    detail: undefined,
  },
  {
    moduleId: 14,
    path: moduleImage14,
    webpPath: moduleImageWebp14,
    title: '第12回 統合失調症における認知機能および機能改善療法（ACUMEN#14）',
    videoTime: '30:43',
    speaker: {
      facilitator: 'Lakshmi N. Yatham教授',
      expert: 'Susan R. McGurk教授 (ボストン大学)',
    },
    detail: undefined,
  },
  {
    moduleId: 4,
    path: moduleImage04,
    webpPath: moduleImageWebp04,
    title: '第13回 双極I型障害における認知障害（ACUMEN#4）',
    videoTime: '16:33',
    speaker: {
      facilitator: 'Eduard Vieta教授 (バルセロナ大学)',
      expert: 'Lakshmi N. Yatham教授 ',
    },
    isNew: true,
    detail: undefined,
  },
  {
    moduleId: 6,
    path: moduleImage06,
    webpPath: moduleImageWebp06,
    title: '第14回 双極II型障害における認知機能（ACUMEN#6）',
    videoTime: '31:34',
    speaker: {
      facilitator: 'Lakshmi N. Yatham教授',
      expert: 'Katherine Burick准教授 (ハーバード大学)',
    },
    isNew: true,
    detail: undefined,
  },
]
