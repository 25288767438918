import { Link } from 'gatsby'
import * as React from 'react'
import styles from './exp-card.module.scss'
import newTag from '@/images/tag-new.svg'

export type CardItemProps = {
  path: string
  webpPath: string
  moduleId?: number
  title: string
  videoTime?: string
  speaker?: {
    facilitator: string
    expert: string
  }
  detail?: string
  isNew?: boolean
}

export const ExpCard: React.FC<{ cardItem: CardItemProps }> = ({
  cardItem,
}) => (
  <div className={styles.card}>
    {cardItem.moduleId ? (
      <Link className={styles.cardLink} to={`/video/${cardItem.moduleId}`}>
        <div className={styles.cardWrap}>
          <div className={styles.cardImage}>
            <picture>
              <source srcSet={cardItem.webpPath} type="image/webp" />
              <img src={cardItem.path} alt="video-thumbnail" />
            </picture>

            <div className={styles.overlay} />
            <div className={styles.play}>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M11.9999 23.6666C5.55642 23.6666 0.333252 18.4435 0.333252 12C0.333252 5.55648 5.55642 0.333313 11.9999 0.333313C18.4434 0.333313 23.6666 5.55648 23.6666 12C23.6596 18.44 18.4399 23.6596 11.9999 23.6666ZM2.66659 12.2006C2.6931 14.6672 3.69494 17.0229 5.45314 18.753C7.21134 20.483 9.58292 21.4468 12.0495 21.4335C14.5162 21.4203 16.8773 20.4311 18.6168 18.6822C20.3563 16.9333 21.3327 14.567 21.3327 12.1003C21.3327 9.63365 20.3563 7.26729 18.6168 5.51841C16.8773 3.76954 14.5162 2.78037 12.0495 2.76711C9.58292 2.75386 7.21134 3.71758 5.45314 5.44765C3.69494 7.17773 2.6931 9.53346 2.66659 12V12.2006ZM9.66659 17.25V6.74998L16.6666 12L9.66659 17.25Z"
                  fill="white"
                />
              </svg>
            </div>
            <div className={styles.videoTime}>{cardItem.videoTime}</div>
            {cardItem.isNew && (
              <div className={styles.newTag}>
                <img src={newTag} alt="" />
              </div>
            )}
          </div>
          <div className={styles.title}>{cardItem.title}</div>
          <div className={styles.videoDetail}>
            {cardItem.speaker && (
              <div className={styles.speaker}>
                <p>ファシリテーター :</p>
                <p>{cardItem?.speaker?.facilitator}</p>
              </div>
            )}
            {cardItem.speaker && (
              <div className={styles.speaker}>
                <p>エキスパート :</p>
                <p> {cardItem?.speaker?.expert}</p>
              </div>
            )}
          </div>
        </div>
      </Link>
    ) : (
      <div className={styles.cardWrap}>
        <div className={styles.cardImage}>
          <img src={cardItem.path} alt="" />

          <div className={styles.comingOverlay} />
          <div className={styles.comingSoon}>Coming Soon...</div>
        </div>
        <div className={styles.title}>{cardItem.title}</div>
        <div className={styles.videoDetail}>
          {cardItem.speaker && (
            <div className={styles.speaker}>
              <p>ファシリテーター:</p>
              <p>{cardItem?.speaker?.facilitator}</p>
            </div>
          )}
        </div>
      </div>
    )}
  </div>
)
